(function(angular) {
	'use strict';

	/**
	 * @module xpsui:controllers
	 */
	angular.module('xpsui:controllers')
		/**
		 *
		 * Requires:
		 * * #routeParams contain 'schema'
		 */
	.controller('xpsui:RegistrySearchV2Ctrl', [
		'$scope',
		'$routeParams',
		'xpsui:SchemaUtil',
		'$translate',
		'xpsui:HttpHandlerFactory',
		'$parse',
		'xpsui:logging',
		'xpsui:QueryFilter',
		'$localStorage',
		function($scope, $routeParams, schemaUtil, $translate, httpFactory, $parse, log, QueryFilter, $localStorage) {

			var httpHandler = httpFactory.newHandler();

			//FIXME this function must be replaced by generic objecttools function shared among client and server if poosible
			$scope.objectPathToSchemaPath = function(objPath) {
				if (objPath) {
					return 'properties.'+objPath.replace(/\./g, '.properties.');
				} else {
					return null;
				}
			};

			$scope.fieldWeigth = function(field) {
				if (field.render && field.render.width) {
					if (field.render.width === 'narrow') {
						return '2 0 100px';
					} else if (field.render.width === 'wide') {
						return '50 0 150px';
					}
				}
				return '10 0 200px';
			};

			$scope.limit = 50;
			$scope.moreData = false;
			$scope.isSearching = false;
			$scope.isExporting = false;
			$scope.arrayOfX = [];
			$scope.selection = [];

	        var retrievedObject = localStorage.getItem($routeParams.schema);
	        var retrievedData = JSON.parse(retrievedObject);

			// current sort definition
			$scope.currSort = {
				field: null,
				order: QueryFilter.sort.ASC
			};

			$scope.sort = QueryFilter.sort;

			$scope.loadStorageCrit = function() {
				var x = {};

				x[$routeParams.schema] = $scope.searchCrits;
				$scope.arrayOfX.push(x);

				if (retrievedData != null) {
					for (var y in retrievedData) {
						$scope.searchCrits.push(retrievedData[y]);
					}
				} else {
					$scope.searchCrits.push({
						op: $scope.allOps[0]
					});
				}

			}

			$scope.addNewCrit = function() {
				var x = {};

				x[$routeParams.schema] = $scope.searchCrits;
				$scope.arrayOfX.push(x);
				$scope.searchCrits.push({
					op: $scope.allOps[0]
				});

				if($localStorage.searchCrits) {
		          x = $localStorage.searchCrits;
		          if($localStorage.searchCrits && $localStorage.searchCrits[$routeParams.schema] > 0) {
		            $scope.searchCrits = $localStorage.searchCrits[$routeParams.schema];
		          }
		        }
		        x[$routeParams.schema] = $scope.searchCrits;
		        $localStorage.searchCrits = x;
			};

			$scope.removeAllCrit = function() {
				var x = {};
				$scope.searchCrits = [];
				$scope.addNewCrit();
			};

			$scope.saveAllCritToStorage = function() {
				var x = {};

				for (var q in $scope.arrayOfX[0]) {
					localStorage.setItem($routeParams.schema, JSON.stringify($scope.arrayOfX[0][q]));
				}
			}

			$scope.clearAllCritFromStorage = function() {
				localStorage.removeItem($routeParams.schema);
				$scope.removeAllCrit();
			}

			$scope.removeCrit = function(idx) {
				var x = {};

				$scope.searchCrits.splice(idx, 1);
				x[$routeParams.schema] = $scope.searchCrits;
			};

			/**
			 * Changes search sort order by field.
			 * Order of sort can be changed by consequent use of function with
			 * same field name.
			 *
			 * @param {string} field name of parameter
			 *
			 */
			$scope.changeSort = function(field) {
				if ($scope.currSort.field === field) {
					// same field clicked twice, change sort order

					if ($scope.currSort.order === QueryFilter.sort.ASC) {
						$scope.currSort.order = QueryFilter.sort.DESC;
					} else {
						$scope.currSort.order = QueryFilter.sort.ASC;
					}
				}

				$scope.currSort.field = field;

				$scope.search();
			};

			$scope.filterKeyPressed = function(event) {
				if(event.which === 13) {
					$scope.search();
				}
			};

			$scope.exportCsv = function() {
				$scope.isExporting = true;

				setTimeout(function() {
					var data = $scope.data;
					var li;

					var htmlData = [];
					htmlData.push('<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><meta http-equiv=Content-Type content="text/html; charset=utf-8"><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table id="tblExport" style="border:1px solid black; "><thead><tr>');
					for (li in $scope.schema.listFields) {
						var lisDef=$scope.schema.listFields[li];
						htmlData.push('<th>'+lisDef.title+'</th>');
					}
					htmlData.push('<tr></thead><tbody>');

					for (var item=0; item<data.length; item++) {
						htmlData.push('<tr>');
						for (li=0; li<$scope.schema.listFields.length; li++) {
							var field = $scope.schema.listFields[li].field;
							var value = data[item][field];
							var fieldDef = $scope.findFieldDefinition(field);
							if (value && value.refData) {
								var text = '';
								var sep = '';
								for (var key in value.refData) {
									text = text + sep + value.refData[key];
									sep = ' | ';
								}
								htmlData.push('<td>' + text +'</td>');
							} else if (value || value === 0) {
								if (_.get(fieldDef, 'fragment.render.component') === "psui-datepicker") {
									htmlData.push('<td>' + value.substr(6, 2) + '.' + value.substr(4, 2) + '.' + value.substr(0, 4) + '</td>');
								} else {
									htmlData.push('<td>' + value + '</td>');
								}
							} else {
								htmlData.push('<td></td>');
							}
						}
						htmlData.push('</tr>');
					}
					htmlData.push('</tbody></table></body></html>');

					var blob = new Blob([htmlData.join('')], {type: 'application/vnd.ms-excel;charset=utf-8'});
					var url  =  window.webkitURL||window.URL;
					var link = document.createElementNS('http://www.w3.org/1999/xhtml', 'a');
					link.href = url.createObjectURL(blob);
					link.download = 'search-export.xls'; // whatever file name you want :)

					var event = document.createEvent('MouseEvents');
					event.initEvent('click', true, false);
					link.dispatchEvent(event);

					$scope.$apply(function() {
						$scope.isExporting = false;
					});
				}, 10);
			};

			$scope.rowSelection = function(evt, idx) {
				if (evt.ctrlKey || evt.metaKey) {
					$scope.data[idx].selected = !$scope.data[idx].selected;

					$scope.selection = $scope.selection.filter(function(v) {return v !== $scope.model[idx].id;});
					if ($scope.data[idx].selected) {
						$scope.selection.push($scope.model[idx].id);
					}
				}
			};

			$scope.setSearch = function(field) {
			};

			$scope.search = function() {
				$scope.model = [];
				$scope.data = [];
				$scope.moreData = false;
				$scope.currPage = 0;
				fetchData();
			};

			$scope.next = function() {
				++$scope.currPage;
				fetchData();
			};

			$scope.findFieldDefinition = function(fieldPath) {
				for (var i = 0; i < $scope.schemaFields.length; i++) {
					if (fieldPath === $scope.schemaFields[i].path) {
						return $scope.schemaFields[i];
					}
				}
			};

			$scope.constructQueryFilter = function() {
				$scope.isSearching = true;

				var qf = QueryFilter.create();

				// add criteria
				for (var i = 0; i < $scope.searchCrits.length; ++i) {
					// skip criteria with empty field
					if ($scope.searchCrits[i].field) {
						var fieldDef = $scope.findFieldDefinition($scope.searchCrits[i].field.path);
						var inObjs = [];

						for (var o in $scope.searchCrits[i].val || [1]) {
							var inObj = {};

							for (var e in fieldDef.fragment.items || [1]) {
								var val = $scope.searchCrits[i].val;
								var path = $scope.searchCrits[i].field.path;
								var type = fieldDef.fragment.type;
								var op = QueryFilter.operation[$scope.searchCrits[i].op.op];

								if ('items' in fieldDef.fragment && Array.isArray(val)) {
									if (!(e in val[o])) {
										continue;
									}
									val = val[o][e];
									path += '.' + e;
									type = fieldDef.fragment.items[e].type;

									if (fieldDef.fragment.items[e].objectLink2) {
										type = 'object';
									}
								}

								if (fieldDef && type === 'number' && val && val+'' === parseInt(val)+'') {
									val = parseInt(val);
								}

								if (fieldDef && type === 'object' && val && val.oid && val.schema) {
									val = val.oid;
									path += '.oid';
								}

								if ((val+'').length === 0) {
									continue;
								}

								if (fieldDef && fieldDef.fragment.type === 'array' && val) {
									inObj[e + ((path.substr(path.length-4) === '.oid')?'.oid':'')] = val;
								}
							}

							if (fieldDef && fieldDef.fragment.type === 'array') {
								inObjs.push(inObj);
							}
						}

						if (fieldDef && 'items' in fieldDef.fragment && Array.isArray($scope.searchCrits[i].val) && fieldDef.fragment.type === 'array') {
							path = $scope.searchCrits[i].field.path;
							op = QueryFilter.operation[$scope.searchCrits[i].op.op];

							if ((['in', 'nin', 'all']).indexOf(op) < 0) {
								op = 'all';
							}

							qf.addCriterium(path, op, inObjs);
						} else {
							qf.addCriterium(path, op, val);
						}
					}
				}

				// add fields
				for (i = 0; i < $scope.schema.listFields.length; ++i) {
					qf.addField($scope.schema.listFields[i].field);
				}
				qf.addField('id'); //ID should be always returned

				// add sorts and limits
				qf.addSort($scope.currSort.field, $scope.currSort.order)
					.setSkip($scope.currPage * $scope.limit)
					.setLimit($scope.limit + 1);

				return qf;
			};

			function fetchData() {
				$scope.isSearching = true;
				var i;
				var qf = $scope.constructQueryFilter();

				httpHandler.http({
					url: '/search/' + schemaUtil.encodeUri(schemaUri),
					method: 'POST',
					data: qf
				}).then(function(data) {
					//success

					var d = data.data;




					$scope.isSearching = false;
					if (d.length > $scope.limit) {
						$scope.moreData = true;
						d = d.slice(0,$scope.limit);
					} else {
						$scope.moreData = false;
					}
					$scope.model = $scope.model.concat(d);
					$scope.data = $scope.data.concat(flattenData(d, $scope.schema.listFields));
					$scope.rowCounter = $scope.data.length;
				}, function(err) {
					// error
					// FIXME notification
				});
			}

			var schemaUri = schemaUtil.decodeUri($routeParams.schema);
			$scope.schema = null;
			$scope.model = [];
			$scope.data = [];

			// prepare known operations
			$scope.allOps = [];
			var id = 0;

			for (var i in QueryFilter.operation) {
				if (QueryFilter.operation.hasOwnProperty(i)) {

					$scope.allOps.push({
						id: id++,
						text: $translate.instant('queryfilter.ops.' + (QueryFilter.operation[i].text || i)),
						op: (QueryFilter.operation[i].code || i)
					});
				}
			}

			$scope.searchCrits = []; // actual row

			function flattenSchema(schema, path, group) {
				var result = [];

				if (!(path && angular.isArray(path))) {
					path = [];
				}

				if (schema.properties) {
					for (var propertyName in schema.properties) {
						if (schema.properties.hasOwnProperty(propertyName)) {
							var property = schema.properties[propertyName];
							var propertyTitle = $translate.instant(property.transCode || property.title);
							var localPath = path.concat(propertyName);

							if (property.properties) {
								result = result.concat(flattenSchema(property, localPath, propertyTitle));
							} else {
								// property has no additional properties
								result.push({
									name: propertyTitle,
									path: localPath.join('.'),
									group: group,
									fragment: property
								});

							}
						}
					}
				}

				return result;
			}

			function flattenData(data, fields) {
				var result = [];

				if (data && angular.isArray(data)) {
					for (var d in data) {
						var dataRow= {};
						for (var f in fields) {
							dataRow[fields[f].field] = $parse(fields[f].field)(data[d]);
						}

						result.push(dataRow);
					}
				}

				return result;
			}

			function removeForcedFields(schemaFields, schema) {
				if(schema.forcedCriteria) {
					for (var j = 0; j < schemaFields.length; j++) {
						schema.forcedCriteria.forEach(function(crit) {
							if (schemaFields[j].path === crit.f) {
								schemaFields.splice(j, 1);
								j--;
							}
						});
					}
				}

			}

			$scope.reflattenFields = function() {
				$scope.data = flattenData($scope.model, $scope.schema.listFields);
			}

			schemaUtil.getCompiledSchema(schemaUri)
			.success(function(data) {
					$scope.schema = data;
					$scope.schemaFields = flattenSchema(data, '');

					removeForcedFields($scope.schemaFields, $scope.schema);
					// FIXME check if schema is correct, there has to be at least one field
					$scope.currSort.field = $scope.schema.listFields[0].field;
					$scope.loadStorageCrit();

					if ($scope.schema.autoSearch) {
						$scope.search()
					}
			})
			.error(function() {
				log.error('Failed to get schema');
				//TODO notification anbout error state
			});

		}]);

}(window.angular));
